interface ApiClientConfig {
  baseUrl: string;
}

interface Config extends Object {
  sentry: {
    dsn: string;
    org?: string;
    project?: string;
  };
  auth0: {
    domain: string;
    clientId: string;
  };
  intercom: {
    appId: string;
  };
  monitoring: {
    enabled: boolean;
  };
  gitSha: string | undefined;
  useLocalStorageAccessToken: boolean;
  publicUrl: string;
  apiClient: ApiClientConfig;
  postHog: {
    apiKey: string;
    host: string;
  };
  shopifyAppName: string;
}

const config: Config = {
  sentry: {
    dsn:
      import.meta.env.VITE_SENTRY_DSN ??
      "https://b52fbe1a581bdf5f9f61a895faa83653@o464891.ingest.us.sentry.io/4506382334164992",
    org: import.meta.env.VITE_SENTRY_ORG,
    project: import.meta.env.VITE_SENTRY_PROJECT,
  },
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN ?? "depict.eu.auth0.com",
    clientId:
      import.meta.env.VITE_AUTH0_CLIENT_ID ??
      "v4rBBuOn19vgSroPGhrTI13AtIFUUhTb",
  },
  intercom: {
    appId: import.meta.env.VITE_INTERCOM_APP_ID ?? "sxdorqr2",
  },
  monitoring: {
    enabled:
      import.meta.env.PROD || import.meta.env.VITE_FORCE_MONITORING === "true",
  },
  gitSha: import.meta.env.VITE_GITSHA,
  publicUrl: import.meta.env.VITE_PUBLIC_URL ?? "/",
  apiClient: {
    // Embedded uses platform.depict.ai, standalone uses lite.depict.ai
    // NOTE: If you change the /api/lite prefix in production, you also need to change the portal-frontend
    // nginx config to forward platform.depict.ai/NEW_PREFIX instead.
    baseUrl: import.meta.env.SSR
      ? import.meta.env.VITE_API_URL
      : `${window.location.origin}/api/lite`,
  },
  useLocalStorageAccessToken: process.env.NODE_ENV === "development",
  postHog: {
    apiKey:
      import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY ??
      "phc_KSTnEtp7dsYroHayAsaSE8PMgHedw6qDea0FvgK4xd9",
    host:
      import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST ??
      "https://eu.i.posthog.com",
  },
  shopifyAppName: import.meta.env.VITE_SHOPIFY_APP_NAME ?? "depict",
};

// print config if localStorage has "depictPrintConfig" set to true
if (!import.meta.env.SSR) {
  if (localStorage.getItem("depictPrintConfig") === "true") {
    console.info({
      config,
    });
  }
}

console.info("App version:", config.gitSha);

export default config;
